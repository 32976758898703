import React from "react";
import { useDataContext } from "../../DataContext";
import { getFieldValue, parseCssSafely } from "../../helpers/Utils";
import fallbackImage from "../../images/silhouette.jpg";
import "./BookingClients.css";
import { Booking, FieldNameToDisplay } from "../../models/ClientLayoutModel";
import { getBookingsForDateSorted } from "../../helpers/Utils";

interface BookingClientsProps {}

const BookingClients: React.FC<BookingClientsProps> = () => {
  const { layoutName, infoStyle, fieldNamesToDisplay, calendars, clients } =
    useDataContext().getData();

  // Find the fields to display
  const titleField = layoutName;
  const imageField =
    fieldNamesToDisplay.find((field) => field.fieldName === "Image") ??
    ({} as FieldNameToDisplay);

  const bookings = getBookingsForDateSorted(new Date().toISOString(), 0);
  return (
    <div
      className="app"
      style={{
        backgroundImage: infoStyle?.backgroundImageBase64
          ? `url(${infoStyle.backgroundImageBase64})`
          : undefined,
      }}
    >
      <div
        className="columns-container"
        style={parseCssSafely(infoStyle.backgroundCss)}
      >
        {titleField && (
          <div style={parseCssSafely(infoStyle.layoutNameCss)}>
            {titleField}
          </div>
        )}
        {bookings.map((booking: Booking) => (
          <div
            key={booking.id}
            style={parseCssSafely(infoStyle.bookingContainerCss)}
          >
            {/* Left column: Booking data */}
            <div className="booking-left-column">
              {fieldNamesToDisplay
                .filter(
                  (field) =>
                    field.fieldName !== "Image" && field.fieldName !== "Title"
                )
                .map((field) => (
                  <div className="booking-field-row" key={field.fieldName}>
                    <div style={parseCssSafely(field.headerFieldCss)}>
                      {field.displayName}:
                    </div>
                    <div style={parseCssSafely(field.valueFieldCss)}>
                      {getFieldValue(
                        booking,
                        field,
                        calendars.find((c) => c.id === booking.calendarId) ??
                          calendars[0],
                        new Date().toISOString(),
                        clients.find((c) => c.id === booking.client?.id) ??
                          undefined
                      )}
                    </div>
                  </div>
                ))}
            </div>

            {/* Right column: Client image */}
            <img
              src={
                clients.find((c) => c.id === booking.client?.id)?.imageBase64 ??
                fallbackImage
              }
              alt={"person"}
              style={parseCssSafely(imageField.valueFieldCss)}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default BookingClients;
