// BookingDaysRows.tsx
import React from "react";
import { Calendar, Booking } from "../../models/ClientLayoutModel";
import { getBookingsForDateSorted, getFieldValue } from "../../helpers/Utils";
import { getDateRange, useCurrentTime } from "../../helpers/DateHelper";
import { useDataContext } from "../../DataContext";

interface RowsWithTilesProps {
  scrollRef: React.RefObject<HTMLDivElement>;
}

const RowsWithTiles: React.FC<RowsWithTilesProps> = ({ scrollRef }) => {
  const { getData } = useDataContext();

  // Function to render calendar header based on rowHeaderDatasetType
  const renderHeader = (date: string) => {
    const dayOfWeekCapitalized =
      new Date(date)
        .toLocaleDateString("da-DK", { weekday: "long" })
        .charAt(0)
        .toUpperCase() +
      new Date(date).toLocaleDateString("da-DK", { weekday: "long" }).slice(1);
    const dateFormatted =
      new Date(date).getDate() + "/" + (new Date(date).getMonth() + 1);

    return (
      <div
        className="day-header"
        style={JSON.parse(getData().infoStyle.dayHeaderCss ?? "{}")}
      >
        <div
          className="day-of-week"
          style={JSON.parse(getData().infoStyle.dayHeaderDayOfWeekCss ?? "{}")}
        >
          {dayOfWeekCapitalized}
        </div>
        <div
          className="date-formatted"
          style={JSON.parse(getData().infoStyle.dayHeaderDateCss ?? "{}")}
        >
          {dateFormatted}
        </div>
      </div>
    );
  };

  // Function to render booking content based on tileDatasetType
  const renderTileContent = (date: string, calendarId: number = 0) => {
    const tilesPerRow = getData().infoStyle.bookingTilesPerRow ?? 5;
    const tilesForDate = getBookingsForDateSorted(date, calendarId);

    if (
      tilesForDate.length === 0 &&
      getData().infoStyle.defaultTextWhenNoBookings
    ) {
      // No bookings for this date
      return (
        <div key={date} className="date-container">
          {renderHeader(date)}
          <div
            className="no-bookings"
            style={JSON.parse(
              getData().infoStyle.defaultTextWhenNoBookingsCss ?? "{}"
            )}
          >
            {getData().infoStyle.defaultTextWhenNoBookings}
          </div>
        </div>
      );
    }

    return (
      <div key={date} className="date-container">
        {renderHeader(date)}

        <div className="bookings">
          {tilesForDate
            .reduce((rows: Booking[][], booking, index) => {
              if (index % tilesPerRow === 0) {
                rows.push([]); // Start a new row
              }
              rows[rows.length - 1].push(booking);
              return rows;
            }, [])
            .map((row, rowIndex) => {
              const blankCount = tilesPerRow - row.length;
              const tileWidth = (1 / tilesPerRow) * 100;
              return (
                <div key={rowIndex} className="booking-row">
                  {row.map((booking, index) => {
                    return (
                      <div
                        key={booking.id}
                        className="booking-box"
                        style={{
                          ...((index + 1) % 2 === 0
                            ? JSON.parse(getData().infoStyle.oddsCss ?? "{}")
                            : JSON.parse(getData().infoStyle.evensCss ?? "{}")),
                          ...JSON.parse(
                            getData().infoStyle.bookingContainerCss ?? "{}"
                          ),
                          width: `${tileWidth}%`, // Append calculated width property
                        }}
                      >
                        {getData().fieldNamesToDisplay.map((field) => (
                          <div
                            key={field.sourceType + field.fieldName}
                            className="table-cell"
                            style={JSON.parse(field.valueFieldCss ?? "{}")}
                          >
                            {getFieldValue(
                              booking,
                              field,
                              getData().calendars.find(
                                (c) => c.id === booking.calendarId
                              ) ?? ({} as Calendar), // The last part satisfies the linter
                              date
                            )}
                          </div>
                        ))}
                      </div>
                    );
                  })}

                  {/* Add blank slots with the same style as evens */}
                  {[...Array(blankCount)].map((_, index) => (
                    <div
                      key={`blank-${date}-${rowIndex}-${index}`}
                      className="booking-box"
                      style={{
                        ...JSON.parse(getData().infoStyle.evensCss ?? "{}"),
                        ...JSON.parse(
                          getData().infoStyle.bookingContainerCss ?? "{}"
                        ),
                        width: `${tileWidth}%`,
                      }}
                    ></div>
                  ))}
                </div>
              );
            })}
        </div>
      </div>
    );
  };

  // Main rendering logic
  const renderCalendars = (calendar: Calendar) => {
    const dateRange = getDateRange();

    return (
      <div className="calendar-container">
        {
          <div
            className="calendar-header"
            style={JSON.parse(getData().infoStyle.calendarTitleCss ?? "{}")}
          >
            {calendar.name}
          </div>
        }
        {dateRange.map((date) => {
          return renderTileContent(date, calendar.id);
        })}
      </div>
    );
  };
  const dateRange = getDateRange();
  return (
    <div
      className="app"
      style={{
        backgroundImage: getData()?.infoStyle?.backgroundImageBase64
          ? `url(${getData().infoStyle?.backgroundImageBase64})`
          : "{}",
      }}
    >
      {
        <div
          className="clock"
          style={JSON.parse(getData()?.infoStyle?.clockCss ?? "{}")}
        >
          {useCurrentTime().toLocaleTimeString([], {
            hour: "2-digit",
            minute: "2-digit",
            hour12: false,
          })}
        </div>
      }
      <div
        className={
          getData().infoStyle.autoscrollOnVerticalOverflow
            ? "scrollable-content"
            : ""
        }
        ref={scrollRef}
        style={JSON.parse(getData().infoStyle.backgroundCss ?? "{}")}
      >
        <div style={JSON.parse(getData().infoStyle.layoutNameCss ?? "{}")}>
          {getData().layoutName}
        </div>
        {!getData() ? (
          <div> No data available</div>
        ) : getData().calendars.length === 0 ? (
          <div>No calendars available.</div>
        ) : !getData().infoStyle.mergeBookingsIntoSingleCalendarView ? (
          getData().calendars.map((calendar) => renderCalendars(calendar))
        ) : (
          dateRange.map((date) => renderTileContent(date))
        )}
      </div>
    </div>
  );
};

export default RowsWithTiles;
