import { Client, ClientLayout } from "../models/ClientLayoutModel";
import { Language, VisitorType } from "../models/ModelTypes";

class DataStore {
  private data: ClientLayout = {} as ClientLayout;

  private language: Language = Language.Danish;

  private client: Client = {} as Client;

  private visitorType: VisitorType = VisitorType.Visitor;
  private visitorName: string = "";
  private visitorPhoneNumber: string = "";
  private visitorCompanyName: string = "";

  setData(newData: ClientLayout) {
    this.data = newData;
  }

  getData(): ClientLayout {
    return this.data;
  }

  setLanguage(newLanguage: Language) {
    this.language = newLanguage;
  }

  getLanguage(): Language {
    return this.language;
  }

  setClient(client: Client) {
    this.client = client;
  }

  getClient() {
    return this.client;
  }

  setVisitorType(type: VisitorType) {
    this.visitorType = type;
  }

  getVisitorType() {
    return this.visitorType;
  }

  setVisitorName(name: string) {
    this.visitorName = name;
  }

  getVisitorName() {
    return this.visitorName;
  }

  setVisitorPhoneNumber(number: string) {
    this.visitorPhoneNumber = number;
  }

  getVisitorPhoneNumber() {
    return this.visitorPhoneNumber;
  }

  setVisitorCompanyName(companyName: string) {
    this.visitorCompanyName = companyName;
  }

  getVisitorCompanyName() {
    return this.visitorCompanyName;
  }

  resetVisitorData() {
    this.client = {} as Client;
    this.visitorType = VisitorType.Visitor;
    this.visitorCompanyName = "";
    this.visitorName = "";
    this.visitorPhoneNumber = "";
  }
}

const dataStore = new DataStore();
export default dataStore;
