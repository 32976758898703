import React, { useState, useEffect, useRef, useCallback } from "react";
import { ClientLayout } from "./models/ClientLayoutModel";
import RowsWithTiles from "./layouts/infoscreen/RowsWithTiles";
import "./App.css";
import ColumnsAndRows from "./layouts/infoscreen/ColumnsAndRows";
import {
  ClientLayoutInfoType,
  ClientLayoutType,
  Language,
} from "./models/ModelTypes";
import RowsWithTables from "./layouts/infoscreen/RowsWithTables";
import { cancelRefreshAt, refreshAt as reloadPageAt } from "./helpers/Utils";
import SingleEvent from "./layouts/infoscreen/SingleEventView";
import Wayfinding from "./layouts/wayfinding/Wayfinding";
import Checkin from "./layouts/checkin/Checkin";
import { getBaseApiUrl } from "./helpers/Constants";
import MenuOfTheWeek from "./layouts/infoscreen/MenuOfTheWeek";
import RoomAvailability from "./layouts/infoscreen/RoomAvailability";
import { useDataContext } from "./DataContext";
import BookingClients from "./layouts/infoscreen/BookingClients";
import CalendarDayView from "./layouts/infoscreen/CalendarDayView";

// Fallback in case react is broken on load
let dataIsLoaded = false;

function App() {
  const { setLanguage, setData, getData } = useDataContext();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const scrollRef = useRef<HTMLDivElement>(null);
  const animationRef = useRef<number | null>(null);
  const dataRef = useRef<ClientLayout | undefined>();
  dataRef.current = getData();
  const dataFetchingIntervalRef = useRef<number | null>(null);

  let scrollAmount = useRef<number>(0);
  const queryParams = new URLSearchParams(window.location.search);
  const id = queryParams.get("layoutid");
  let apiUrl = getBaseApiUrl() + `ClientLayout/guid/${id}`;

  const dataFromServerAsString = useRef<string | null>("");

  const fetchData = useCallback(() => {
    const controller = new AbortController();
    const timeoutId = setTimeout(() => controller.abort(), 10000); // 10 seconds timeout on request

    fetch(apiUrl, { signal: controller.signal })
      .then(async (response) => {
        clearTimeout(timeoutId); // Clear the timeout if request completes

        if (!response.ok) {
          console.error("Error fetching data");
          setLoading(false);
          return;
        }

        let tmpDataFromServer = await response.json();
        let tmpDataFromServerAsString = JSON.stringify(tmpDataFromServer);

        // I could not get any other comparisons to work for some reason :(
        // I tried a custom deepEqual, string comparison, and even lodash isEqual, with no luck
        // Cba wasting more time on this
        if (
          tmpDataFromServerAsString?.length !==
          dataFromServerAsString.current?.length
        ) {
          dataFromServerAsString.current = tmpDataFromServerAsString;
          const dataAsJson = tmpDataFromServer as ClientLayout;

          console.log("New data:", tmpDataFromServer);
          setLanguage(
            dataAsJson.checkinWorkflow?.defaultLanguage ?? Language.Danish
          );
          setData(dataAsJson);
        } else {
          console.log("No new data");
        }

        setLoading(false);
        setError(null);
        reloadPageAt(1, 0, 0); // Reload page at 01:00:00
        dataIsLoaded = true;
      })
      .catch((error) => {
        if (error.name === "AbortError") {
          console.error("Request timed out");
        } else {
          setError(error.message);
        }
        setLoading(false);
        cancelRefreshAt(); // Stop refreshing if server is down
      });
  }, [apiUrl, dataFromServerAsString, setData, setLanguage]);

  useEffect(() => {
    if (dataFetchingIntervalRef.current) {
      clearInterval(dataFetchingIntervalRef.current);
    }

    fetchData();
    const intervalSeconds =
      (getData()?.dataFetchingIntervalSeconds ?? 60) * 1000;
    dataFetchingIntervalRef.current = window.setInterval(
      fetchData,
      intervalSeconds
    );

    return () => {
      if (dataFetchingIntervalRef.current) {
        clearInterval(dataFetchingIntervalRef.current);
      }
    };
  }, [fetchData, getData]);

  useEffect(() => {
    if (
      scrollRef.current &&
      getData()?.infoStyle.autoscrollOnVerticalOverflow
    ) {
      const scrollElement = scrollRef.current;
      let lastFrameTime = 0;
      const scrollSpeed =
        getData()?.infoStyle.autoscrollOnVerticalOverflowSpeed ?? 20;

      const smoothScroll = (time: number) => {
        const deltaTime = time - lastFrameTime;
        lastFrameTime = time;

        scrollAmount.current += (scrollSpeed * deltaTime) / 1000;
        scrollElement.scrollTop = scrollAmount.current;

        if (
          scrollElement.scrollTop + scrollElement.clientHeight >=
          scrollElement.scrollHeight
        ) {
          setTimeout(
            () => {
              scrollAmount.current = 0;
            },
            getData()?.infoStyle.autoscrollOnVerticalOverflowEndPauseSeconds
              ? getData().infoStyle
                  .autoscrollOnVerticalOverflowEndPauseSeconds * 1000
              : 3000
          );
        }

        animationRef.current = requestAnimationFrame(smoothScroll);
      };

      animationRef.current = requestAnimationFrame(smoothScroll);
    }

    return () => {
      if (animationRef.current) {
        cancelAnimationFrame(animationRef.current);
      }
    };
  }, [getData]);

  const renderContent = (data: ClientLayout) => {
    switch (data.clientLayoutType) {
      case ClientLayoutType.Info:
        switch (data.infoStyle.clientLayoutInfoType) {
          case ClientLayoutInfoType.RowsWithTiles:
            return <RowsWithTiles scrollRef={scrollRef} />;
          case ClientLayoutInfoType.ColumnsAndRow:
            return <ColumnsAndRows />;
          case ClientLayoutInfoType.RowsWithTables:
            return <RowsWithTables scrollRef={scrollRef} />;
          case ClientLayoutInfoType.SingleEvent:
            return <SingleEvent />;
          case ClientLayoutInfoType.MenuOfTheWeek:
            return <MenuOfTheWeek />;
          case ClientLayoutInfoType.RoomAvailability:
            return <RoomAvailability />;
          case ClientLayoutInfoType.BookingClients:
            return <BookingClients />;
          case ClientLayoutInfoType.CalendarDayView:
            return <CalendarDayView />;
          default:
            console.error("NYI");
            break;
        }
        break;
      case ClientLayoutType.Wayfinding:
        return <Wayfinding />;
      case ClientLayoutType.Checkin:
        return <Checkin />;
      default:
        return <></>;
    }
  };

  return (
    <>
      {loading && (
        <div className="loader-container">
          <div className="spinner"></div>
          <p>Loading...</p>
        </div>
      )}
      {!getData() && error && <p>Error: {error}</p>}
      {getData() && renderContent(getData())}
    </>
  );
}

setTimeout(() => {
  const now = new Date(); // Current time
  const future = new Date(now.getTime() + 10 * 1000); // 10 seconds from now

  if (!dataIsLoaded) {
    console.log("Reloading in 10 seconds...");
    reloadPageAt(future.getHours(), future.getMinutes(), future.getSeconds());
  }
}, 60000); //Reload if we do not have data within 1 minute

export default App;
